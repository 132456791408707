import _ from 'lodash'
import { Institution } from '~/models/Institution'

export const useInstitutionStore = defineStore('provider', () => {
  /* === init === */
  const config = useRuntimeConfig()
  const { $directus, $readItems } = useNuxtApp()

  /* === state === */
  const payload: Ref<InstitutionPayload[]> = ref([])
  const institutions = computed(() =>
    payload.value.map((data) => Institution.fromPayload(data))
  )

  /* === getters === */
  const providerCount = computed(() => institutions.value.length)
  const shuffledProviders = computed(() => {
    const res = _.clone(institutions.value)
    return shuffle(res)
  })

  /* === actions === */
  async function initInstitutions() {
    payload.value = await $directus().request(
      $readItems('institutions', {
        sort: 'name',
        filter: {
          data_sources: { envs: { _contains: config.public.dataEnv } },
        },
        fields: [
          'id',
          'name',
          'intermediate_provider',
          { favicon: ['id'] },
          {
            hero_image: ['id', 'title', 'author', 'license', 'license_url'],
          },
          {
            logo: ['id'],
          },
          {
            translations: [
              'place',
              'title_official',
              'title',
              'slug',
              'summary',
              'content',
              'languages_code',
            ],
          },
          'web_collection_url',
          'website_url',
        ],
      })
    )
  }

  function _collectLocalizedSlugs(institution: Institution) {
    const slugs = []
    for (const lang in institution.slug) {
      const localizedSlug = institution.slug[lang as LanguageCode]
      slugs.push(localizedSlug)
    }
    return slugs
  }

  function getBySlug(slug: string): Institution | null {
    /**
     * Finds an institution from the institution list via providing a (localized) slug. The localization of the slug does not matter as all variants are traversed.
     * The function is implemented as action on purpose: The needed provider list will always already be loaded beforehand via global init middleware.
     */
    return (
      institutions.value.find((institution) =>
        _collectLocalizedSlugs(institution).includes(slug)
      ) || null
    )
  }

  /**
   * Finds an institution from the provider list via providing a name. The name of a provider is a respectively unique (unlocalized) property (like an identifier).
   * The function is implemented as action on purpose: The needed provider list will always already be loaded beforehand via global init middleware.
   */
  function getByName(name: string): Institution | null {
    return (
      institutions.value.find((institution) => institution.name === name) ||
      null
    )
  }

  return {
    // state
    payload,
    institutions,
    // getters
    shuffledProviders,
    providerCount,
    // actions
    initInstitutions,
    getByName,
    getBySlug,
  }
})
